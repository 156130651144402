@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=SUSE:wght@100..800&family=Syne:wght@400..800&display=swap');

* {
	margin: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	overflow-x: hidden;
	background-color: #fbfbfb;
	color: #111111;
	font-family: 'Syne', sans-serif;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	overflow-y: hidden;
}
button {
	outline: none;
	cursor: pointer;
	border: none;
	background-color: transparent;
}
a {
	text-decoration: none;
	color: inherit;
}
img {
	user-drag: none;
}
input,
button {
	/* font-family: 'Montserrat', sans-serif; */
	font-family: 'SUSE', sans-serif;
}
input {
	background-color: transparent;
	outline: none;
	/* border: none; */
	/* font-family: 'Montserrat', sans-serif; */
	font-family: 'SUSE', sans-serif;
}
input:focus,
textarea:focus {
	outline: none;
}
::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-thumb {
	background: #fd5526;
	border-radius: 6px;
}
::-webkit-scrollbar-track {
	background: #000000;
}
::selection {
	color: #000000;
	background-color: #fd5526;
}
