.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-col {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.routes-container {
	/* margin-top: 100px; */
}

/*--------------------Navbar----------------------*/
.navbar {
	width: 100vw;
	height: 100px;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	inset: 0;
	z-index: 10;
	font-family: 'Syne', sans-serif;
	padding: 0 9%;
}
.navbar.active {
	background-color: #000000;
}
.nav-logo {
	height: 50px;
	max-width: 400px;
}
.nav-logo img {
	height: 100%;
	width: 100%;
}
.nav-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}
.nav-link {
	display: inline-block;
	font-size: 19px;
	width: 120px;
	padding: 0 10px;
	text-align: center;
	color: #ffffff;
	transition: all 0.8s ease-in-out;
	letter-spacing: 0.5px;
	padding: 10px 10px;
	border-radius: 10px;
	margin: 0 5px;
	background: linear-gradient(transparent 50%, #fd5526 0) 0 var(--_d, 0) / 100%
			200% no-repeat,
		linear-gradient(transparent 50%, transparent 0) 0 var(--_d, 0) / 100% 200%
			no-repeat;
}
.nav-link.active {
	--_d: 100%;
	color: #000 !important;
}
.nav-link:hover {
	--_d: 100%;
	color: #000 !important;
}
.nav-burger {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 40px;
	height: 40px;
	position: relative;
	transition: 0.1s;
	cursor: pointer;
	border-radius: 50%;
	border: 2.5px solid #ffffff;
	display: none;
	z-index: 11;
}
.nav-burger span {
	width: 20px;
	height: 2.5px;
	background-color: #ffffff;
	display: block;
	margin: 2px 0;
	border-radius: 2px;
	transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.nav-burger:hover span {
	transform: scale(1.05);
	transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
}

/*------------------------Hero section------------------------------*/
.hero-section {
	width: 100%;
	height: 100vh;
	background-color: #000000;
	padding: 80px 9% 0;
	color: #ffffff;
	align-items: flex-start;
	position: relative;
	background: url('./assets/homebg.svg') no-repeat center center/cover;
}
.hero-section h1 {
	font-size: 55px;
	font-weight: 700;
	z-index: 2;
	/* transform: translateY(400px);
	opacity: 0; */
}
.hero-section p {
	font-size: 35px;
	z-index: 2;
	margin-top: 10px;
	font-family: 'SUSE', sans-serif;
	max-width: 80%;
	/* transform: translateY(400px);
	opacity: 0; */
}
.hero-buttons {
	margin-top: 50px;
	gap: 30px;
	z-index: 2;
	/* transform: translateY(400px);
	opacity: 0; */
}
.hero-img {
	position: absolute;
	bottom: 0;
	right: 9%;
	height: calc(100% - 100px);
	max-width: 500px;
	z-index: 1;
	filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.9));
	/* transform: translateX(100vw); */
}
.custom-button {
	min-width: 250px;
	padding: 15px;
	border: 2px solid #fd5526;
	background-color: #fd5526;
	color: #000000;
	font-size: 22px;
	font-family: 'Syne', sans-serif;
	font-weight: 700;
	transition: all 0.5s ease-in-out;
	z-index: 2;
}
.custom-button:active {
	transform: scale(0.95);
	transition: all 0.5s ease-in-out;
}
.custom-button:hover {
	background-color: #000000;
	color: #fd5526;
	transition: all 0.5s ease-in-out;
}
.heading {
	text-align: left;
	background-color: transparent;
	color: #000000;
	font-size: 22px;
	position: relative;
	overflow: hidden;
	z-index: 2;
}
.heading span {
	display: inline-block;
	width: 150px;
	height: 2px;
	background-color: #000000;
	margin-left: 20px;
	margin-bottom: 5px;
}
.home-content {
	margin-top: -1px;
	/* background-image: url('./assets/sectionbg.svg'); */
	background-repeat: no-repeat;
	background-size: cover;
}
.home-about {
	padding: 50px 9% 0;
}
.home-about-content {
	margin: 50px 0 80px;
}
.home-about-content h2 {
	font-size: 30px;
	max-width: 70%;
	font-weight: 700;
}
.home-about-content p {
	font-size: 20px;
	max-width: 100%;
	margin-top: 10px;
	letter-spacing: 0.2px;
	font-family: 'SUSE', sans-serif;
}
.home-services {
	padding: 0px 9% 50px;
}
.home-features {
	padding: 50px 9%;
}
.home-services-content {
	text-align: center;
	padding: 50px 50px;
	background-color: #f4f8fb;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
/* .home-services-bg {
	position: absolute;
	height: 1500px;
	width: 1500px;
	background-color: #fd9173;
	z-index: 1;
	right: -700px;
	top: 20px;
	transform: rotate(45deg);
} */
.home-service-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	margin-top: 50px;
	z-index: 2;
}
.home-service-card {
	background-color: #ffffff;
	width: 250px;
	padding: 20px;
	border-radius: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s, box-shadow 0.3s;
	text-align: left;
	position: relative;
	z-index: 2;
	overflow: hidden;
	backdrop-filter: blur(10px);
}
.home-service-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.circle-svg {
	position: absolute;
	height: 150px;
	width: 150px;
	right: -35px;
	top: -35px;
	z-index: 1;
}
.home-service-icon {
	width: 70px;
	height: 70px;
	margin-bottom: 15px;
}
.home-service-icon img {
	height: 100%;
	width: 100%;
	filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.9));
}
.home-service-card h3 {
	color: #3498db;
	font-size: 18px;
	margin-bottom: 10px;
	z-index: 2;
}
.home-service-card p {
	color: #2c3e50;
	font-size: 16px;
	font-family: 'SUSE', sans-serif;
	z-index: 2;
}
.home-features {
	background-color: #e3f2ff;
}
.home-features-content {
	padding: 50px 0;
	height: fit-content;
	gap: 30px;
}
.home-features-img {
	height: 100%;
	max-width: 300px;
}
.home-features-img img {
	height: 100%;
	width: 100%;
	filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.9));
}
.home-features-left,
.home-features-right {
	gap: 50px;
	align-items: flex-start;
}
.home-feature h1 {
	font-size: 28px;
	margin-bottom: 10px;
}
.home-feature p {
	max-width: 350px;
	font-size: 18px;
	font-family: 'SUSE', sans-serif;
}
.success-stories {
	width: 100%;
	padding: 50px 9%;
	/* gap: 10px; */
	background-color: #000;
	background: url(./assets/successbg.svg) no-repeat center/cover;
	position: relative;
}
.success-stories-title {
	width: 60%;
	height: 500px;
	background-color: #000;
	overflow: hidden;
	position: relative;
	z-index: 5;
}
.success-stories-title::before {
	position: absolute;
	left: 0;
	top: 0;
	content: '';
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
.success-stories-title img {
	height: 100%;
	width: 100%;
	z-index: 1;
}
.success-stories-title h1 {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 40px;
	padding: 20px;
	background: rgba(0, 0, 0, 0.4);
	box-shadow: 2px 2px 10px 0 rgba(255, 255, 255, 0.37);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	width: fit-content;
	text-align: center;
}
.success-story-top,
.success-story-bottom {
	/* gap: 10px; */
	width: 100%;
	overflow: hidden;
}
.success-stories .success-story {
	height: 500px;
	overflow: hidden;
	cursor: pointer;
	position: relative;
}
.success-story-top .success-story {
	width: 40%;
}
.success-story-bottom .success-story:nth-child(1) {
	width: 50%;
}
.success-story-bottom .success-story:nth-child(2) {
	width: 50%;
}
.success-story img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: all 1s ease-in-out;
}
.success-story img:hover {
	transform: scale(1.2);
	transition: all 1s ease-in-out;
	animation: blurImage 1s ease-in-out;
}
.success-story span {
	position: absolute;
	width: 240px;
	height: 120px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	font-size: 25px;
	font-family: 'Syne', sans-serif;
	font-weight: 700;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 2px 2px 4px 0 rgba(255, 255, 255, 0.37);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: #fd5526;
	text-align: center;
	clip-path: circle(0% at 50% 50%);
	transition: all 1s ease-in-out;
}

.success-story:hover span {
	clip-path: circle(100% at 50% 50%);
	transition: all 1s ease-in-out;
}
@keyframes blurImage {
	0% {
		filter: blur(0px);
	}
	0% {
		filter: blur(10px);
	}
	100% {
		filter: blur(0px);
	}
}
.home-testimonials-section {
	width: 100vw;
	overflow: hidden;
	position: relative;
	background-color: #000000;
	color: #fff;
	position: relative;
	/* border-top: 2px solid #fd5526; */
}
.home-testimonials {
	display: flex;
	/* transition: transform 1s ease-in-out; */
}
.home-testimonial {
	min-width: 100%;
	height: 550px;
	position: relative;
	padding: 50px 9%;
}
.quote {
	position: absolute;
	left: 9%;
	top: 0;
	height: 200px;
	width: 120px;
	text-align: bottom;
	background-color: #fd5526;
}
.quote i {
	font-size: 40px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	color: #000000;
}
.home-testimonial-content {
	padding: 50px 0 50px 15%;
}
.home-testimonial-content h1 {
	font-size: 45px;
	margin-top: 50px;
	letter-spacing: 1px;
	line-height: 50px;
}
.home-testimonial-content h6 {
	text-align: right;
	margin: 50px 20px 0 0;
	font-size: 18px;
	color: #ffffff;
}
.dots-container {
	width: 100%;
	position: absolute;
	bottom: 30px;
}
.dot {
	height: 8px;
	width: 8px;
	margin: 0 5px;
	background-color: #ffffff;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
.dot.active {
	background-color: #fd5526;
	height: 12px;
	width: 12px;
	transition: all 0.3s ease-in-out;
}
.home-contact {
	background-color: #000000;
	background-image: url('./assets/contactbg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #fff;
	width: 100%;
	height: 400px;
	text-align: center;
	padding: 50px 9%;
	position: relative;
	z-index: 2;
}
.home-contact::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
}
.home-contact h1 {
	font-size: 35px;
	z-index: 2;
}
.home-contact p {
	font-size: 20px;
	margin: 5px auto 30px;
	z-index: 2;
	font-family: 'SUSE', sans-serif;
	max-width: 60%;
}
/*--------------------------Footer--------------------------*/
footer {
	background-color: #000000;
	color: #ffffff;
	padding: 30px 9% 0;
}
.footer-top {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 50px 0;
	flex-wrap: wrap;
	gap: 20px;
}
.footer-top img {
	height: 50px;
	max-width: 300px;
}
.footer-top p {
	max-width: 300px;
	font-size: 18px;
	font-family: 'SUSE', sans-serif;
}
.footer-top h2 {
	margin-bottom: 15px;
}
.footer-top a {
	font-size: 18px;
	font-family: 'SUSE', sans-serif;
	letter-spacing: 0.3px;
	transition: all 0.5s ease-in-out;
}
.footer-top a:hover {
	color: #fd5526;
	transition: all 0.5s ease-in-out;
}
.footer-social-links a {
	font-size: 22px;
	margin-right: 40px;
}
.footer-bottom {
	padding: 30px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	width: 100%;
	text-align: center;
	font-size: 16px;
}

/*----------------------Contact Page--------------------------*/
.contact-hero {
	width: 100vw;
	height: 550px;
	background: url('./assets/contactusbg.jpg') no-repeat center center/cover;
	color: #ffffff;
	position: relative;
}
.contact-hero::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 1;
}
.contact-hero-content {
	width: 100%;
	text-align: center;
	z-index: 2;
}
.contact-hero-content h1 {
	font-size: 35px;
	font-weight: 700;
}
.contact-hero-content p {
	font-size: 20px;
	font-family: 'SUSE', sans-serif;
	max-width: 60%;
	margin: 10px auto;
}
.contact-details-section {
	background-color: #000;
}
.contact-details {
	position: relative;
	z-index: 3;
	width: fit-content;
	background: rgba(255, 255, 255, 0.25);
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.37);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	align-items: flex-start;
	/* gap: 30px; */
	margin: -100px auto 0;
	flex-wrap: wrap;
}
.contact-links {
	justify-content: flex-start;
	align-items: flex-start;
	gap: 30px;
	padding: 20px;
}
.contact-link {
	height: 230px;
	width: 450px;
	background-color: #fff;
	padding: 20px;
	gap: 20px;
	justify-content: flex-start;
	border-radius: 10px;
	font-family: 'Syne', sans-serif;
	text-align: left;
	transition: all 0.5s ease-in-out;
}
.contact-link img {
	width: 100px;
	height: 100px;
	filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.9));
	transition: all 0.5s ease-in-out;
}

.contact-link-button:hover.contact-link-button img {
	transform: scale(1.1);
	transition: all 0.5s ease-in-out;
}
.contact-social {
	justify-content: center;
}
.contact-link-info h2,
.contact-link h3 {
	font-size: 20px;
}
.contact-link-info p {
	font-size: 18px;
	font-family: 'SUSE', sans-serif;
	margin-top: 5px;
}
.contact-link-social img {
	height: 80px;
	width: 80px;
	margin: 0 5px;
	transition: all 0.5s ease-in-out;
	filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.9));
}
.contact-link-social img:hover {
	transform: scale(1.1);
	transition: all 0.5s ease-in-out;
}
.contact-form-section {
	max-width: 500px;
	background-color: #fff;
	padding: 20px;
	margin: 20px 20px;
	border-radius: 10px;
	text-align: center;
	position: relative;
	overflow: hidden;
}
.contact-bg {
	position: absolute;
	width: 100%;
	height: 400px;
	top: 0px;
	left: -110px;
	z-index: 1;
	transform: scale(3);
}
.contact-form-section h1 {
	margin: 20px auto 10px;
	z-index: 2;
}
.contact-form-section p {
	margin: 0px auto 10px;
	z-index: 2;
}
.contact-form {
	width: 100%;
	/* padding: 20px; */
	background-color: transparent;
	align-items: center;
	z-index: 2;
}
.input-container {
	align-items: flex-start;
	width: fit-content;
	gap: 5px;
	margin-top: 10px;
}
.input-container label {
	font-size: 16px;
	margin-left: 5px;
	font-weight: 600;
}
.input-container small {
	font-size: 14px;
	margin-left: 5px;
	color: #fd5526;
}
.contact-form .input-container:nth-child(1) small {
	color: #000;
}
.input-container input,
.input-container select,
.input-container textarea {
	width: 400px;
	height: 50px;
	padding: 5px 10px;
	background-color: transparent;
	outline: none;
	border: 2px solid #000;
	font-family: 'SUSE', sans-serif;
	font-size: 16px;
	border-radius: 5px;
	transition: all 0.5s ease-in-out;
}
.input-container textarea {
	height: 120px;
	resize: none;
}
input[type='checkbox'] {
	width: 20px !important;
	height: 20px !important;
	float: left;
	margin-right: 5px;
	accent-color: #fd5526;
}
input[type='checkbox']:disabled + label {
	color: red;
}
.input-checkbox {
	max-width: 400px;
	text-align: left;
	font-family: 'SUSE', sans-serif;
}
.input-container input:focus,
.input-container select:focus,
.input-container textarea:focus {
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
	transition: all 0.5s ease-in-out;
}
input[type='checkbox']:focus {
	box-shadow: none;
}
.input-container input::placeholder,
.input-container textarea::placeholder {
	color: #000;
}
.submit-button {
	width: 400px;
	height: 50px;
	padding: 5px 10px;
	font-size: 22px;
	font-weight: 500;
	font-family: 'Syne', sans-serif;
	margin-top: 20px;
	background-color: #fd5526;
	border: 2px solid #fd5526;
	color: #000;
	transition: all 0.5s ease-in-out;
}
.submit-button:hover {
	background-color: #000;
	color: #fd5526;
	transition: all 0.5s ease-in-out;
}

/*------------------------About Page--------------------------*/
.about-hero {
	width: 100vw;
	background-color: #000;
	color: #fff;
	padding: 150px 9% 50px;
	text-align: left;
}
.about-hero h1 {
	font-size: 55px;
	font-weight: 800;
	font-family: 'Syne', sans-serif;
	letter-spacing: 1px;
}
.about-hero > h1 span {
	display: none;
}
.about-hero-details {
	justify-content: space-between;
	gap: 30px;
	/* flex-wrap: wrap; */
}
.about-hero-details p {
	max-width: 350px;
	font-family: 'SUSE', sans-serif;
}
.about-hero-left,
.about-hero-right {
	height: 350px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}
.about-hero-middle img {
	max-width: 40vw;
	height: 350px;
	border-radius: 20px;
}
.about-hero-right img {
	max-width: 350px;
	height: 200px;
	border-radius: 20px;
}
.about-vision-mission {
	width: 100%;
	padding: 50px 9%;
	color: #000;
	background-color: #fff;
}
.vision-mission-details {
	gap: 40px;
	padding: 50px 0;
}
.vision-mission {
	justify-content: space-between;
	background-image: linear-gradient(to right, #fd5526, #fff);
	max-width: 800px;
	border-top-left-radius: 150px;
	border-bottom-left-radius: 150px;
	gap: 40px;
	/* padding: 20px; */
}
.vision-mission h1 {
	font-size: 30px;
	margin-bottom: 10px;
}
.vision-mission p {
	font-size: 17px;
	letter-spacing: 0.3px;
	font-family: 'SUSE', sans-serif;
}
.vision-mission-icon {
	border-radius: 50%;
	background-color: transparent;
}
.vision-mission-icon img {
	height: 200px;
	width: 200px;
}
.vision-mission-details .vision-mission:nth-child(2) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 150px;
	border-bottom-right-radius: 150px;
	background-image: linear-gradient(to right, #fff, #fd5526);
	margin-left: 10%;
	gap: 20px;
}
.about-services {
	width: 100%;
	padding: 50px 9%;
	gap: 50px;
	align-items: flex-start;
	background-color: #e3f2ff;
	overflow: hidden;
}
.about-title {
	display: none;
}
.about-title-inside {
	display: inline-block;
}
.about-services img {
	max-width: 45vw;
	max-height: 500px;
}
.about-service {
	margin-top: 30px;
}
.about-service h1 {
	margin-bottom: 10px;
}
.about-service p {
	margin-bottom: 10px;
	font-family: 'SUSE', sans-serif;
	letter-spacing: 0.3px;
	font-size: 16px;
}
.about-values {
	width: 100%;
	padding: 50px 9% 150px;
	gap: 50px;
	background-color: #000;
	background: url('./assets/valuesbg.svg') no-repeat center center/cover;
}
.about-values-details {
	gap: 50px 20px;
	flex-wrap: wrap;
}
.about-values-details .about-value:nth-child(2) {
	transform: translateY(50px);
}
.about-values-details .about-value:nth-child(3) {
	transform: translateY(-40px);
}
.about-values-details .about-value:nth-child(4) {
	transform: translateY(30px);
}
.about-values-details .about-value:nth-child(5) {
	transform: translateY(10px);
}
.about-values .heading {
	color: #fff;
	margin-bottom: 50px;
}
.about-values .heading span {
	background-color: #fff;
}
.about-value {
	width: 220px;
	height: 370px;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	text-align: center;
	background-color: #e1eff8;
	border-radius: 20px;
}
.about-value img {
	width: 100px;
	height: 100px;
	margin-top: 20px;
	filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
	transition: all 0.5s ease-in-out;
}
.about-value:hover.about-value img {
	transform: scale(1.1);
	transition: all 0.5s ease-in-out;
}
.about-value h2 {
	font-size: 25px;
	margin: 20px 0 10px;
}
.about-value p {
	font-size: 18px;
	font-family: 'SUSE', sans-serif;
	letter-spacing: 0.2px;
}
.about-contact {
	background-image: url('./assets/aboutbg.jpg');
}
.services-contact {
	background-image: url('./assets/servicescontact.jpg');
}
.services-contact p {
	font-size: 25px;
}

/*--------------------Services Page-----------------------*/
.services-hero {
	width: 100%;
	min-height: 100vh;
	padding: 150px 9% 50px;
	background: url('./assets/servicesbg.jpg') no-repeat center center/cover;
	color: #fff;
}
.services-hero-details {
	padding: 40px 40px;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 4px 4px 10px 0px rgba(255, 255, 255, 0.37);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.4);
}
.services-hero h1 {
	font-size: 35px;
}
.services-hero h1 span {
	color: #fd5526;
}
.services-section {
	width: 100%;
	padding: 50px 9%;
	color: #000;
}
.services-details {
	padding: 50px 0;
	gap: 80px;
}
.service {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 50px;
}
.services-details .service:nth-child(even) {
	flex-direction: row-reverse;
}
.service-image {
	width: 500px;
	height: 500px;
	position: relative;
}
.service-image::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 30px;
	left: 30px;
	background-color: transparent;
	border: 2px solid #000;
}
.services-details .service:nth-child(even) .service-image::after {
	left: -30px;
}
.service-image img {
	height: 100%;
	width: 100%;
	z-index: 2;
}
.service-details {
	max-width: 40%;
	margin-top: 20px;
	margin-left: 50px;
}
.services-details .service:nth-child(even) .service-details {
	margin-left: 0px;
	margin-right: 50px;
}
.service-details h1 {
	margin-bottom: 10px;
	font-size: 30px;
}
.service-details p {
	margin-bottom: 10px;
	font-size: 17px;
	font-family: 'SUSE', sans-serif;
	letter-spacing: 0.3px;
}
.service-details h2 {
	margin: 20px 0 5px;
	font-size: 20px;
}
.service-details p span {
	font-family: 'Syne', sans-serif;
	font-weight: 700;
}
.popup {
}
.contact-popup {
	display: flex;
	transform: scale(0);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	/* clip-path: circle(0% at 50% 50%); */
}
.contact-popup.show {
	/* clip-path: circle(100% at 50% 50%); */
	/* transition: all 0.5s ease-in-out; */
}
.contact-popup.hide {
	/* clip-path: circle(0% at 50% 50%); */
	/* transition: all 0.5s ease-in-out; */
}
.contact-popup-details {
	background-color: #fff;
	padding: 20px;
	position: relative;
	border-radius: 10px;
}
.close-button {
	font-size: 35px;
	position: absolute;
	top: 10px;
	right: 0px;
	border-radius: 50%;
	color: #fd5526;
	z-index: 20;
}
.contact-popup-details h4 {
	margin: 10px 0;
}
.contact-popup-details .custom-button {
	width: 100%;
	font-family: 'SUSE', sans-serif;
	font-weight: 500;
	font-size: 18px;
	padding: 10px;
}
.contact-popup-details .contact-form .input-container:nth-child(1) small {
	color: #fd5526;
}
.contact-popup .input-container input,
.contact-popup .input-container select {
	width: 350px;
	height: 40px;
	font-size: 14px;
}
.contact-popup .input-checkbox {
	width: 350px;
	font-size: 14px;
}
.contact-popup .submit-button {
	width: 350px;
	height: 40px;
	font-size: 20px;
}
.loader {
	width: 100vw;
	padding: 0 50px;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	/* animation: backgroundChange 3.9s ease-in-out forwards; */
}
.loader img {
	height: 80px;
	max-width: 500px;
	/* animation: move 3.9s ease-in-out forwards; */
	/* position: absolute;
	transform: translate(-50%, -50%); */
}
.loader-num {
	overflow: hidden;
	height: 50px;
	width: fit-content;
	font-size: 20px;
	font-weight: 800;
	position: absolute;
	bottom: 30px;
	left: 20px;
	color: #fd5526;
	font-family: 'SUSE';
}
.loader-num h1 {
	animation: numChange 3.3s ease-in-out forwards;
}
.loader-bar {
	position: absolute;
	height: 5px;
	background-color: #fd5526;
	width: 20%;
	bottom: 20px;
	left: 10px;
	animation: widthChange 3.3s ease-in-out forwards;
}
@keyframes numChange {
	0% {
		transform: translateY(0px);
	}
	25% {
		transform: translateY(-50px);
	}
	50% {
		transform: translateY(-100px);
	}
	75% {
		transform: translateY(-150px);
	}
	100% {
		transform: translateY(-200px);
	}
}
@keyframes widthChange {
	0% {
		width: 20%;
	}
	25% {
		width: 40%;
	}
	50% {
		width: 70%;
	}
	75% {
		width: 90%;
	}
	100% {
		width: 100%;
	}
}

@media (max-width: 1024px) {
	.navbar,
	footer {
		padding: 0 2%;
	}
	.hero-section {
		height: fit-content;
		padding: 150px 2% 50px;
	}
	.home-services-bg {
		display: none;
	}
	.home-about {
		padding: 50px 2% 0;
	}
	.home-services {
		padding: 0px 2% 50px;
	}
	.home-features {
		padding: 50px 2%;
	}
	.success-stories {
		padding: 50px 2%;
	}
	.success-stories-title h1 {
		font-size: 25px;
	}
	.success-stories-title {
		height: 400px;
	}
	.success-stories .success-story {
		height: 400px;
	}
	.home-testimonial {
		padding: 50px 2%;
	}
	.quote {
		left: 2%;
		height: 180px;
		width: 110px;
	}
	.home-testimonial-content h1 {
		font-size: 35px;
		margin-top: 50px;
		letter-spacing: 1px;
		line-height: 50px;
	}
	.about-hero {
		padding: 150px 2% 50px;
	}
	.about-hero-left,
	.about-hero-right {
		height: fit-content;
	}
	.about-services {
		padding: 50px 2%;
	}
	.about-values {
		padding: 50px 2% 150px;
	}
	.services-hero {
		padding: 150px 2% 50px;
	}
	.services-hero-details {
		padding: 30px;
	}
	.services-hero h1 {
		font-size: 30px;
	}
	.services-section {
		padding: 50px 2%;
	}
	.contact-links {
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.contact-form-section {
		padding: 20px 20px 40px;
	}
}
@media (max-width: 768px) {
	.navbar {
		height: 90px;
	}
	.routes-container {
		/* margin-top: 90px; */
	}
	.navbar,
	footer {
		padding: 0 5%;
	}
	.hero-section {
		padding: 150px 5% 50px;
	}
	.hero-section p{
		max-width: 100%;
	}
	.nav-logo {
		height: 45px;
		padding: 0;
		z-index: 11;
	}
	.nav-burger {
		display: flex;
	}
	.open span:nth-child(1) {
		width: 15px;
		transform: rotate(45deg) translate(4px, 5px);
		transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	}
	.open span:nth-child(2) {
		opacity: 0;
	}
	.open span:nth-child(3) {
		width: 15px;
		transform: rotate(-45deg) translate(4px, -5px);
		transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
	}
	.nav-menu {
		width: 100vw;
		height: fit-content;
		background-color: #000000;
		position: absolute;
		top: 0px;
		z-index: 10;
		flex-direction: column;
		left: 0;
		transition: all 0.5s ease-in-out;
		overflow: hidden;
		padding: 90px 0 20px;
	}
	.visible {
		clip-path: circle(100% at 50% 50%);
	}
	.hidden {
		position: absolute;
		top: 0px;
		clip-path: circle(0% at 50% 50%);
	}
	.nav-link {
		padding: 0;
		margin: 10px 0;
		width: 150px;
		height: 50px;
		padding: 13px 0;
		align-self: center;
		text-align: center;
		color: #ffffff;
	}
	.hero-section {
		align-items: center;
		flex-direction: column;
	}
	.hero-section h1 {
		font-size: 35px;
	}
	.hero-section p {
		font-size: 25px;
		z-index: 2;
		margin-top: 10px;
		font-family: 'SUSE';
	}
	.hero-buttons {
		flex-direction: column;
		width: 100%;
	}
	.hero-img {
		/* filter: blur(1.5px); */
		opacity: 0.5;
		max-width: 100%;
	}
	.custom-button {
		width: 100%;
	}
	.home-about {
		padding: 50px 5% 0;
	}
	.home-about-content h2 {
		max-width: 100%;
	}
	.heading span {
		width: 80px;
	}
	.big-heading span {
		top: 75%;
	}
	.home-services {
		padding: 0;
	}
	.home-services-content {
		padding: 50px 5%;
	}
	.home-service-cards {
		flex-wrap: wrap;
		align-items: center;
	}
	.home-features {
		padding: 50px 5%;
	}
	.home-features-content {
		flex-wrap: wrap;
	}
	.home-features-img {
		max-width: 250px;
	}
	.home-feature h1 {
		font-size: 22px;
	}
	.home-feature p {
		max-width: 100%;
	}
	.success-stories {
		padding: 50px 5%;
		gap: 20px;
	}
	.success-stories-title {
		height: 300px;
	}
	.success-stories-title h1 {
		font-size: 20px;
	}
	.success-story-bottom,
	.success-story-top {
		gap: 20px;
	}
	.success-stories .success-story {
		height: 300px;
	}
	.home-testimonial {
		padding: 50px 5%;
	}
	.quote {
		left: 5%;
		height: 100px;
		width: 80px;
	}
	.quote i {
		font-size: 30px;
	}
	.home-testimonial-content {
		padding: 50px 0 50px 0;
	}
	.home-testimonial-content h1 {
		font-size: 25px;
		letter-spacing: 0.5px;
		line-height: 30px;
	}
	.home-testimonial-content h6 {
		margin: 30px 20px 0 0;
		font-size: 16px;
	}
	.home-contact {
		padding: 50px 5%;
	}
	.home-contact h1 {
		font-size: 30px;
	}
	.home-contact p {
		font-size: 20px;
		margin: 5px auto 30px;
		max-width: 100%;
	}
	.footer-top {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.footer-top p {
		max-width: 100%;
	}
	.about-hero {
		padding: 150px 5% 50px;
	}
	.about-hero > h1 {
		margin-bottom: 30px;
		text-align: center;
	}
	.about-hero > h1 span {
		display: inline-block;
	}
	.about-hero-details {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.about-hero-details p {
		max-width: 100%;
	}
	.about-hero-left h1 {
		display: none;
	}
	.about-hero-middle {
		order: 1;
	}
	.about-hero-left {
		order: 2;
		height: fit-content;
		gap: 20px;
		align-items: center;
	}
	.about-hero-right {
		order: 3;
		height: fit-content;
		gap: 20px;
		align-items: center;
	}
	.about-hero img {
		width: 100%;
		max-width: 100%;
		max-height: 280px;
	}
	.about-hero-right img {
		width: 100%;
		max-width: 400px;
		max-height: 350px;
	}
	.about-vision-mission {
		padding: 50px 5%;
	}
	.vision-mission {
		flex-direction: column;
		justify-content: center;
		background-image: linear-gradient(to bottom, #fd5526, #fff);
		max-width: 350px;
		border-top-left-radius: 200px;
		border-bottom-left-radius: 0px;
		border-top-right-radius: 200px;
		text-align: center;
	}
	.vision-mission h1 {
		padding: 0 20px;
	}
	.vision-mission p {
		padding: 0 20px;
	}
	.vision-mission-details .vision-mission:nth-child(2) {
		flex-direction: column-reverse;
		border-top-left-radius: 200px;
		border-bottom-left-radius: 0;
		border-top-right-radius: 200px;
		border-bottom-right-radius: 0px;
		background-image: linear-gradient(to bottom, #fd5526, #fff);
		margin-left: 0%;
	}
	.about-title {
		display: inline-block;
		margin-bottom: 30px;
	}
	.about-title-inside {
		display: none;
	}
	.about-services {
		padding: 50px 5%;
		align-items: center;
		flex-wrap: wrap;
		gap: 20px;
	}
	.about-services img {
		max-width: 80vw;
		max-height: 500px;
	}
	.about-contact {
		height: 500px;
	}
	.about-contact h1 {
		margin-bottom: 20px;
	}
	.services-hero {
		padding: 120px 5% 50px;
	}
	.services-hero-details {
		padding: 20px;
	}
	.services-hero h1 {
		font-size: 22px;
		letter-spacing: 0.5px;
	}

	.services-section {
		padding: 50px 5%;
	}
	.service {
		align-items: center;
		flex-direction: column;
	}
	.services-details .service:nth-child(even) {
		flex-direction: column;
	}
	.service-image {
		width: calc(100% - 20px);
		max-height: 300px;
	}
	.service-image::after {
		top: 10px;
		left: 10px;
	}
	.services-details .service:nth-child(even) .service-image::after {
		left: 10px;
	}
	.service-details {
		max-width: 100%;
		margin-top: 20px;
		margin-left: 0px;
	}
	.services-details .service:nth-child(even) .service-details {
		margin-left: 0px;
		margin-right: 0px;
	}
	.service-details h1 {
		font-size: 25px;
		text-align: center;
	}
	.service-details h2 {
		text-align: center;
		margin: 30px 0 20px;
	}
	.service-desc {
		text-align: center;
	}
}
@media (max-width: 500px) {
	.success-stories-title {
		width: 100%;
	}
	.success-story-top,
	.success-story-bottom {
		flex-direction: column;
	}
	.success-story-top .success-story {
		width: 100%;
	}
	.success-story-bottom .success-story:nth-child(1) {
		width: 100%;
	}
	.success-story-bottom .success-story:nth-child(2) {
		width: 100%;
	}
	.contact-hero {
		padding: 0 5%;
	}
	.contact-hero-content {
		margin-bottom: 50px;
	}
	.contact-hero-content p {
		max-width: 100%;
	}
	.contact-details {
		width: 100%;
	}
	.contact-link {
		width: 350px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.contact-link-info p {
		word-wrap: break-word;
	}
	.contact-form-section {
		max-width: 100%;
	}
	.input-container input,
	.input-container select,
	.input-container textarea,
	.submit-button {
		width: 350px;
	}
	.input-checkbox {
		max-width: 350px;
	}
	input[type='checkbox'] {
		width: 20px;
		height: 20px;
		float: left;
		margin-right: 5px;
		accent-color: #fd5526;
	}
	.about-values {
		padding: 50px 5% 100px;
	}
	.about-services img {
		max-width: 100%;
		max-height: 500px;
	}
	.about-values-details .about-value:nth-child(2) {
		transform: translateY(0);
	}
	.about-values-details .about-value:nth-child(3) {
		transform: translateY(0);
	}
	.about-values-details .about-value:nth-child(4) {
		transform: translateY(0);
	}
	.about-values-details .about-value:nth-child(5) {
		transform: translateY(0);
	}
	.about-value {
		width: 100%;
		height: fit-content;
	}
	.loader img {
		height: 50px;
		max-width: 400px;
	}
	.loader-num {
		bottom: 15vh;
	}
	.loader-bar {
		bottom: 10vh;
	}
}
